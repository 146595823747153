/* eslint-disable camelcase */
import { useQuery } from '@apollo/react-hooks';
import { T } from '@deity-io/falcon-i18n';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '@deity-io/falcon-ecommerce-uikit';
import { NEWS_POST } from '../newsQuery';
import NewsSidebar from '../NewsSidebar/NewsSidebar';
import './NewsDetails.scss';
import Helmet from 'react-helmet';

const cdnBaseUrl = 'https://cdn2.emmezeta.hr/media/';
const imgSrcRegex = /\{\{media url=&quot;(.+?)&quot;\}\}/g;

const NewsDetails = () => {
  const { newsId } = useParams();

  const { data, loading, error } = useQuery(NEWS_POST, {
    variables: {
      url_key: newsId,
    },
  });

  if (error) {
    console.log('Error: ', error.message);
    return <h2>Error occured while loading data.</h2>;
  }

  const content = data && data.newsPost.content.replace(imgSrcRegex, `${cdnBaseUrl}$1`);
  const post = data && data.newsPost;

  return (
    <div className="news-details">
      {post && (
        <Helmet>
          <title>{post.title}</title>
          {post.meta_title && <meta property="og:title" content={post.meta_title} />}
          {post.meta_description && <meta property="og:description" content={post.meta_description} />}
        </Helmet>
      )}

      {loading && <Loader />}

      <main className="news-details__main-content">
        <div className="news-details__column">
          <div className="news-details__column-main">
            <div className="news-details__title">
              <h1>{data && data.newsPost.title}</h1>
            </div>

            <div className="news-details__content">
              {data && data.newsPost.image !== 'false' && (
                <div className="news-details__image">
                  <img src={data.newsPost.image} alt="" />
                </div>
              )}

              <div className="news-details__post">
                <div
                  dangerouslySetInnerHTML={{
                    __html: content,
                    // __html: data && data.newsPost.content,
                  }}
                />
                <span className="news-details__post__date">
                  <T id="blog.published" />
                  {data && data.newsPost.publish_date}
                </span>
              </div>
            </div>
          </div>

          <NewsSidebar />
        </div>
      </main>
    </div>
  );
};

export default NewsDetails;
